"use client";
import React from "react";
import { useTranslations } from "next-intl";
import { PillarsSection } from "./PillarsSection";
import {
  pushDataLayerEvent,
  pushDataLayerEventAsync,
} from "@/analytics/analytics.datalayer";
import { Link as NextIntlLink } from "@/i18n/routing";
import Link from "next/link";
import { directory } from "@/analytics";

function PriorityCityPillars({ city }: { city: string }) {
  const t = useTranslations("ClientLandingPage");

  const pillars = [
    {
      title: t("the_pillars.matching.title"),
      description: t("the_pillars.matching.description"),
      cta: (
        <NextIntlLink
          href="/matching/survey"
          onClick={async () => {
            directory.getMatchedClicked({ source: "pillars_cta" });
            await pushDataLayerEventAsync("get_matched_clicked");
          }}
        >
          {t("the_pillars.matching.cta")}
        </NextIntlLink>
      ),
      hasNewTag: true,
    },
    {
      title: t("the_pillars.local_know_how.title"),
      description: t("the_pillars.local_know_how.description", { city }),
      cta: (
        <Link
          href="#directory"
          onClick={() => {
            directory.pillarItemClicked({
              source_page: "landing_page",
              pillar_item: "local_know_how",
            });
            pushDataLayerEvent("pillar_item_local_know_how_clicked");
          }}
        >
          {t("the_pillars.local_know_how.cta", { city })}
        </Link>
      ),
    },
    {
      title: t("the_pillars.built_by_therapists.title"),
      description: t("the_pillars.built_by_therapists.description"),
      cta: (
        <Link
          href="#about-us"
          onClick={() => {
            directory.pillarItemClicked({
              source_page: "landing_page",
              pillar_item: "about_us",
            });
            pushDataLayerEvent("pillar_item_about_us_clicked");
          }}
        >
          {t("the_pillars.built_by_therapists.cta")}
        </Link>
      ),
    },
  ];

  return <PillarsSection pillars={pillars} />;
}

export { PriorityCityPillars };
