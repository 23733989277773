import React from "react";
import { profile as api } from "@/api/entities";
import GreenTag from "@/design-system/components/GreenTag";
import RedTag from "@/design-system/components/RedTag";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { Box, Flex } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import styles from "./ProfileCard.module.scss";
import ProfileImage from "../Picture/ProfileImage";

function ProfileCard({ profile }: { profile: api.ProfileCompact }) {
  const t = useTranslations("Profile");

  const onCardClick = (e: React.MouseEvent) => {
    // Navigate to href in a new tab, ensure to prevent navigation if e.defaultPrevented is true,
    // which means we might have clicked on a link inside the card
    if (!e.defaultPrevented) {
      window.open(`/find-a-therapist${profile.partial_uri}`, "_blank");
    }
  };

  return (
    <Box
      className={styles.container}
      width={"263px"}
      minHeight={"479px"}
      height={"100%"}
      onClick={onCardClick}
    >
      {/* Profile picture */}
      <Box position={"relative"}>
        <div className={styles.imageContainer}>
          <ProfileImage
            key={"placeholder"}
            src={
              profile.profile_picture_url
                ? profile.profile_picture_url
                : "/images/profile_picture_round_rect.svg"
            }
            alt={t("images_alt.profile_image", {
              fullName: profile.full_name,
              jobTitle: profile.jobtitle,
            })}
            aspectRatio={1}
            className={styles.image}
            priority
          />
        </div>
      </Box>

      <Box px={"4"}>
        {/* Profile name, job title */}
        <Box py={"3"}>
          <Flex align={"baseline"} pb={"1"} wrap={"wrap"}>
            <Box>
              <TextV2 textStyle={"Headline S"} weightStyle={"medium"}>
                {profile.full_name}
              </TextV2>
            </Box>
          </Flex>
          <TextV2 textStyle={"Body M"} color={"gray"} clamp={2}>
            {profile.jobtitle}
          </TextV2>
        </Box>

        {/* Profile availability tag */}
        <Box pb={"3"}>
          <ProfileAvailabilityTag available={profile.is_available} />
        </Box>

        {/* Profile professional statement */}
        {profile.professional_statement && (
          <Box pb={"3"}>
            <TextV2
              textStyle={"Body S"}
              dangerouslySetInnerHTML={{
                __html: profile.professional_statement,
              }}
              clamp={4}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

function ProfileAvailabilityTag({ available }: { available: boolean }) {
  const t = useTranslations("Profile");

  return available ? (
    <GreenTag>{t("available_for_new_clients")}</GreenTag>
  ) : (
    <RedTag>{t("unavailable_for_new_clients")}</RedTag>
  );
}

export { ProfileCard };
